export const USER_SIGNIN = "USER_SIGNIN";
export const USER_SIGNUP = "USER_SIGNUP";
export const USER_UPDATE = "USER_UPDATE";
export const VERIFY_OTP = "VERIFY_OTP";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";

export const FETCH_USER = "FETCH_USER";
// export const UPDATE_USER = "UPDATE_USER";
// export const FETCH_USERS = "FETCH_USERS";

export const FETCH_TASK = "FETCH_TASK";
export const FETCH_TASKS = "FETCH_TASKS";
export const UPDATE_TASK = "UPDATE_TASK";

export const FETCH_TASK_OFFER = "FETCH_TASK_OFFER";
export const FETCH_TASK_OFFERS = "FETCH_TASK_OFFERS";
export const UPDATE_TASK_OFFER = "UPDATE_TASK_OFFER";

export const FETCH_PROVIDERS = "FETCH_PROVIDERS";

export const FETCH_MESSAGE = "FETCH_MESSAGE";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const UPDATE_MESSAGES = "UPDATE_MESSAGES";
export const FETCH_GROUPED_MESSAGES = "FETCH_GROUPED_MESSAGES";

export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export const FETCH_BADGES = "FETCH_BADGES";
